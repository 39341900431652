import React from "react"
import { graphql } from "gatsby"

import ParallaxHooklessImage from "../../components/parallax-hookless-image/ParallaxHooklessImage"
import TextBlock from "../../components/text-block/TextBlock"

import { Head } from "../../components/head/Head"
import MarkdownContainer from "../../components/markdown-container/MarkdownContainer";

export default ({ data }) => {
  return (
    <React.Fragment>
      <Head title="Alex & Alexis - Registry" imageSrc={data.alexisLooking.childImageSharp.fluid.src} />
      <ParallaxHooklessImage
        src={data.alexisLooking.childImageSharp.fluid.src}
        height={data.alexisLooking.childImageSharp.fluid.presentationHeight}
        width={data.alexisLooking.childImageSharp.fluid.presentationWidth}
      >
        <h1>Registry</h1>
      </ParallaxHooklessImage>
      <TextBlock>

        <MarkdownContainer markdownString={data.registry.html} />
      </TextBlock>
    </React.Fragment>
  )
}

export const query = graphql`
  {
    alexisLooking: file(relativePath: { eq: "front-porch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3440) {
          src
          presentationHeight
          presentationWidth
        }
      }
    }


    registry: markdownRemark(frontmatter: { title: { eq: "registry" } }) {
      html
    }
  }
`
